/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h3: "h3",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Post Office"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.h3, null, "Opening Times"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Tuesday"), " : 9:30 - 12:30")), "\n", React.createElement(_components.p, null, "The Burton Outreach Post Office is currently held in the vestry of St Andrews Parish Church."), "\n", React.createElement(_components.p, null, "The service is supported by local volunteers."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
